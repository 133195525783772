:root {
  font-size: 2vw;
}

@media (min-width: $bp-small) {
  :root {
    font-size: 62.5%;
  }
}

@media (min-width: $bp-medium) and (min-height: $bp-medium) and (orientation: landscape) {
  :root {
    font-size: 1.15vh;
  }
}

html {
  font-size: 1.6rem;
  color: $color-grey;
  font-display: auto;
}

body {
  font-family: "Raleway", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

h1,
h2 {
  font-size: 5.2rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -0.05rem;
  line-height: 0.77;
  font-family: "Cabin Condensed", "Arial", sans-serif;
}

h3 {
  margin: 0 0 0.3rem;
  font-weight: bolder;
  font-size: 1.2em;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  font-size: 1em;

  + li {
    margin-top: $space/2;
  }
}

a {
  color: inherit;
  text-decoration: underline rgba($color-white, 0.7);

  &:focus,
  &:hover {
    outline: none; // replaced with other focus styles!
  }

  &:hover {
    text-decoration: underline currentColor;
  }

  &:focus {
    color: $color-primary;
    text-decoration: none;
    background-color: $color-white;
    outline: 2px solid $color-white;
    outline-offset: -1px;
  }
}
