html,
body {
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $space;
}

.header,
.main,
.footer {
  width: 25rem;
  margin: 0 auto;

  @media (min-width: $bp-medium) {
    margin-left: $space;
  }

  @media (min-width: $bp-large) {
    margin-left: auto;
  }
}
